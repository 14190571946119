import React, { useEffect, useState } from 'react';
import ActivityForm from '../../components/Activity/ActivityForm';
import apiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import SpinnerOverlay from '../../components/helpers/SpinnerOverlay';

const CreateActivityBanner = () => {
    const navigate = useNavigate();
    const [loading, setBlogLoading] = useState(false);
    const [initialMainBanner, setInitialMainBanner] = useState({
        type: 'header',
        brand: '66f5c9ada328a4f6b7a8b383',
        link: 'https://example.com',
        sort: 0,
        visible: true
    });
    const [initialBodyBanners, setInitialBodyBanners] = useState([]);

    const SaveActivityBanner = async (data) => {
        setBlogLoading(true);
        const [sucess, error] = await apiService.post('activity', data);

        setBlogLoading(false);
        if(error) {
            toast.error("Neuspelo kreiranje activity bannera!");
            return;
        }

        if(sucess) {
            toast.success("Activity banner uspesno kreiran!");
            navigate("/activity/view");
        }
    }

    return (
        <div className="App">
            <h1>Activity Page Management</h1>
            <ActivityForm
                initialMainBanner={initialMainBanner}
                initialBodyBanners={initialBodyBanners}
                SaveActivityBanner={SaveActivityBanner}
            />
            <SpinnerOverlay isLoading={loading} />
        </div>
    );
};

export default CreateActivityBanner;