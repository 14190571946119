import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import LoginForm from "../pages/Login/Login";
import BannerPage from "../pages/Banner/BannerPage";
import CreateBanner from "../pages/Banner/CreateBanner";
import EditBanner from "../pages/Banner/EditBanner";
import CreateActivityBanner from "../pages/Activity/CreateActivityBanner";
import ActivityPage from "../pages/Activity/ActivityPage";
import EditActiviryBanner from "../pages/Activity/EditActivityBanner";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/*" element={<PublicRoute />}>
                <Route path="login" element={<LoginForm />} />
            </Route>
            <Route path="/*" element={<ProtectedRoute />}>
                <Route path="banner">
                    <Route path="view" element={<BannerPage />} />
                    <Route path="edit/:id" element={<EditBanner />} />
                    <Route path="create" element={<CreateBanner />} />
                </Route>

                <Route path="activity">
                    <Route path="view" element={<ActivityPage />} />
                    <Route path="create" element={<CreateActivityBanner />} />
                    {/* <Route path="edit/:id" element={<EditActiviryBanner />} /> */}
                </Route>

                <Route path="*" element={<Navigate to="/login" replace />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes;