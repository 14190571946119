import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import apiService from '../../services/ApiService';
import SpinnerOverlay from '../helpers/SpinnerOverlay';

const BannerForm = ({ bannerToEdit }) => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [contentImage, setContentImage] = useState(null);
  const [brandId, setBrandId] = useState('');
  const [type, setType] = useState('');
  const [link, setLink] = useState('');
  const [sort, setSort] = useState('');
  const [homeSort, setHomeSort] = useState('');
  const [availableSorts, setAvailableSorts] = useState([]);
  const [availableHomeSorts, setAvailableHomeSorts] = useState([]);
  const [visible, setVisible] = useState(null);
  const [loading, setBlogLoading] = useState(false);

  useEffect(() => {
    if (bannerToEdit) {
      setContentImage(bannerToEdit.contentImage);
      setBrandId(bannerToEdit.brandId);
      setType(bannerToEdit.type);
      setLink(bannerToEdit.link);
      setSort(bannerToEdit.sort);
      setHomeSort(bannerToEdit.homeSort);
      setVisible(bannerToEdit.visible);
    }
  }, [bannerToEdit]);

  const getAvailableSortNumbers = async (brandId, type) => {
    try {
      const [success, error] = await apiService.get(`banners/sort?brandId=${brandId}&type=${type}`);
      if (error) throw new Error("Greska pri dohvatanju slobodnih brojeva!");
      setAvailableSorts(success.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getAvailableHomeSortNumbers = async () => {
    try {
      const [success, error] = await apiService.get(`banners/home-sort?type=body`);
      if (error) throw new Error("Greska pri dohvatanju slobodnih brojeva!");
      setAvailableHomeSorts(success.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (brandId) {
      getAvailableSortNumbers(brandId, 'body');
    }
  }, [brandId]);

  useEffect(() => {
    if (brandId) {
      getAvailableSortNumbers(brandId, 'title');
    }
  }, [brandId]);

  useEffect(() => {
    getAvailableHomeSortNumbers();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!contentImage || !brandId || !type || !sort || isNaN(sort) || isNaN(homeSort)) {
      toast.warn('Sva polja su obavezna, "sort" i "home sort" moraju biti broj!');
      return;
    }

    const formData = new FormData();
    formData.append("contentImage", contentImage);
    formData.append("brandId", brandId);
    formData.append("type", type);
    formData.append("link", link);
    formData.append("sort", parseInt(sort));
    formData.append("homeSort", parseInt(homeSort));
    formData.append("visible", visible == null ? false : visible);

    if (bannerToEdit) {
      updateBanner(bannerToEdit._id, formData);
    } else {
      createBanner(formData);
    }
  };

  const createBanner = async (newBanner) => {
    setBlogLoading(true);
    const [success, error] = await apiService.post("banners", newBanner);
    setBlogLoading(false);

    if (error) {
      toast.error(error.response.data.error.message || "Greška pri čuvanju bannera!");
    } else {
      toast.success("Baner uspesno sacuvan!");
      resetForm();
      navigate('/banner/view');
    }
  };

  const updateBanner = async (id, newBanner) => {
    setBlogLoading(true);
    const [success, error] = await apiService.put(`banners/${id}`, newBanner);
    setBlogLoading(false);

    if (error) {
      toast.error(error.response.data.error.message || "Greška pri čuvanju bannera!");
    } else {
      toast.success("Baner uspešno izmenjen!");
      resetForm();
      navigate('/banner/view');
    }
  };

  const resetForm = () => {
    setContentImage(null);
    setBrandId('');
    setType('');
    setLink('');
    setSort('');
    setHomeSort('');
  };

  const handleImageChange = (e) => setContentImage(e.target.files[0]);

  const getBrands = async () => {
    const [success, error] = await apiService.get("brands");
    if (error) {
      toast.error("Greska pri preuzimanju brendova!");
    } else {
      setBrands(success.data);
      if (success.data[0]) setBrandId(success.data[0]._id);
    }
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <div className="container mt-5">
      <h2>{bannerToEdit ? 'Izmeni Banner' : 'Dodaj Banner'}</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="contentImage" className="form-label">Slika</label>
          <input
            type="file"
            className="form-control"
            id="contentImage"
            accept="image/*"
            onChange={handleImageChange}
            required={!bannerToEdit}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="brandId" className="form-label">Brand ID</label>
          <select
            className="form-select"
            id="brandId"
            value={brandId}
            onChange={(e) => setBrandId(e.target.value)}
          >
            {brands.map((brand) => (
              <option key={brand._id} value={brand._id}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="type" className="form-label">Tip Bannera</label>
          <select
            className="form-select"
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option value="" disabled>Odaberite tip</option>
            <option value="title">Header</option>
            <option value="body">Body</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="link" className="form-label">Link</label>
          <input
            type="text"
            className="form-control"
            id="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="sort" className="form-label">Sort</label>
          <input
            type="number"
            className="form-control"
            id="sort"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            min="1"
            max="99"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="homeSort" className="form-label">Home Sort</label>
          <input
            type="number"
            className="form-control"
            id="homeSort"
            value={homeSort}
            onChange={(e) => setHomeSort(e.target.value)}
            min="1"
            max="99"
            required
          />
        </div>

        {bannerToEdit && (
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="visible"
              checked={visible}
              onChange={(e) => setVisible(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="visible">Visible</label>
          </div>
        )}

        <button type="submit" className="btn btn-primary">
          {bannerToEdit ? 'Ažuriraj Banner' : 'Dodaj Banner'}
        </button>
      </form>
      <SpinnerOverlay isLoading={loading} />
      <br />
      <p>Slobodni body sort brojevi: {availableSorts.join(', ')} <br />
        Slobodni home sort brojevi: {availableHomeSorts.join(', ')}</p>
    </div>
  );
};

export default BannerForm;
