import React, { useEffect, useState } from 'react';
import apiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const BannerPage = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]);
  const [version, setVersion] = useState(0);

  const deleteBanner = async (id) => {
    const [success, error] = await apiService.delete("banners/" + id)

    if (error) {
      toast.error(error.response.data.error.message ? error.response.data.error.message : "Greška pri brisanju banera!");
      return;
    }

    setBanners(banners.filter(banner => banner._id !== id));
    toast.success("Banner uspesno izbrisan!");
  };

  const editBanner = (id) => {
    navigate("/banner/edit/" + id)
  };

  const getBanners = async () => {
    const [success, error] = await apiService.get("banners?visibility=all")

    if (error) {
      toast.error("Greska pri ucitavanju banera!");
      return;
    }

    setBanners(success.data)
  };

  const getBannersVersion = async () => {
    const [success, error] = await apiService.get("banners/version")

    if (error) {
      toast.error("Greška pri učitavanju verzije!");
      return;
    }

    setVersion(success.data);
  }

  useEffect(() => {
    getBanners();
    getBannersVersion();
  }, [])

  return (
    <div className="container">
      <div className="mt-5">
        <h2>Lista Bannera</h2>
        <p>Verzija: {version}</p>
        <div className="row">
          {banners.map((banner, index) => (
            <div key={index} className="col-12 col-lg-4 mb-4">
              <div className="card">
                <img crossOrigin="anonymous" src={process.env.REACT_APP_CONTENT_URL + banner.contentImage} className="card-img-top" alt="Banner" />
                <div className="card-body">
                  <h5 className="card-title">{banner.brand}</h5>
                  <p className="card-text">Tip: {banner.type == "title" ? "Header" : "Body"}</p>
                  <p className="card-text">Sort: {banner.sort}</p>
                  {banner.type !== "title" && <p className="card-text">Home Sort: {banner.homeSort}</p>}
                  <p className="card-text">Vidljivost: {banner.visible ? "DA" : "NE"}</p>
                  <a href={banner.link} className="btn btn-primary" target="_blank" rel="noreferrer">Posetite Link</a>
                  <button onClick={() => editBanner(banner._id)} className="btn btn-warning ms-2">Izmeni</button>
                  <button onClick={() => deleteBanner(banner._id)} className="btn btn-danger ms-2">Obriši</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannerPage;