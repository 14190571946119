import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import apiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import BannerForm from '../../components/Banner/BannerForm';

const EditBanner = () => {
  const { id } = useParams();
  const [bannerToEdit, setBannerToEdit] = useState(null);

  const getBanner = async () => {
    const [success, error] = await apiService.get("banners/" + id)

    if (error) {
      toast.error(error.response.data.error.message ? error.response.data.error.message : "Greška pri čuvanju banera!");
      return;
    }

    setBannerToEdit(success.data);
  }

  useEffect(() => {
    if (id) {
      getBanner();
    }
  }, [])

  return (
    <div className="container">
      <BannerForm bannerToEdit={bannerToEdit} />
    </div>
  );
};

export default EditBanner;