import moment from 'moment';
import './App.css';
import AppRoutes from './routes/AppRoutes';
import 'moment/locale/sr';
import "./services/interceptor"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  moment.locale('sr');
  return (
    <div>
      <AppRoutes />
      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
    </div>
  );
}

export default App;
