import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

const ActivityForm = ({ initialMainBanner, initialBodyBanners, SaveActivityBanner }) => {
    const [mainBanner, setMainBanner] = useState(null);
    const [mainImage, setMainImage] = useState(null);

    const [bodyBanners, setBodyBanners] = useState([]);
    const [bodyBannerImages, setBodyBannerImages] = useState([]);

    // Funkcija za promenu podataka glavnog bannera
    const handleMainBannerChange = (e) => {
        const { name, value } = e.target;
        setMainBanner((prev) => ({ ...prev, [name]: value }));
    };

    // Funkcija za promenu slike glavnog bannera
    const handleMainImageChange = (e) => {
        setMainImage(e.target.files[0]);
    };

    // Funkcija za promenu podataka za Body Banner
    const handleBodyBannerChange = (index, e) => {
        const { name, value } = e.target;
        const newBodyBanners = [...bodyBanners];
        newBodyBanners[index] = { ...newBodyBanners[index], [name]: value };
        setBodyBanners(newBodyBanners);
    };

    // Funkcija za promenu slike za Body Banner
    const handleBodyBannerImageChange = (index, e) => {
        const newImages = [...bodyBannerImages];
        newImages[index] = e.target.files[0];
        setBodyBannerImages(newImages);
    };

    // Funkcija za dodavanje novog Body Bannera
    const handleAddBodyBanner = () => {
        setBodyBanners([
            ...bodyBanners,
            {
                type: 'body',
                brand: '66f5c9ada328a4f6b7a8b383',
                link: 'https://example.com',
                sort: bodyBanners.length + 1,
                visible: true,
            },
        ]);
    };

    // Funkcija za brisanje Body Bannera
    const handleDeleteBodyBanner = (index) => {
        const newBodyBanners = bodyBanners.filter((_, i) => i !== index);
        const newBodyBannerImages = bodyBannerImages.filter((_, i) => i !== index);
        setBodyBanners(newBodyBanners);
        setBodyBannerImages(newBodyBannerImages);
    };

    // Funkcija za submit forme
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();

        mainBanner.type = "title";
        // Dodavanje glavnog bannera u formData
        formData.append('mainBanner', JSON.stringify(mainBanner));
        if (mainImage) {
            formData.append('mainImage', mainImage);
        }

        // Dodajemo bodyBanners kao niz objekata
        formData.append('bodyBanners', JSON.stringify(bodyBanners));

        // Dodajemo sve slike iz bodyBannerImages
        bodyBannerImages.forEach((image, index) => {
            if (image) {
                formData.append(`banners`, image);
            }
        });

        // Logovanje sadržaja FormData
        for (const [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        SaveActivityBanner(formData);
    };

    useEffect(() => {
        if(initialMainBanner !== null ) {
            setBodyBanners(initialBodyBanners);
            setMainBanner(initialMainBanner);
        }
    }, [initialMainBanner, initialMainBanner])


    return (
        <Form onSubmit={handleSubmit}>
            <h2>Edit Main Banner</h2>
            <Form.Group as={Row} controlId="mainBannerType">
                <Form.Label column sm="2">Type:</Form.Label>
                <Col sm="10">
                    <Form.Control
                        disabled
                        required
                        type="text"
                        name="type"
                        value={mainBanner?.type}
                        onChange={handleMainBannerChange}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="mainBannerBrand">
                <Form.Label column sm="2">Brand:</Form.Label>
                <Col sm="10">
                    <Form.Control
                        required
                        type="text"
                        name="brand"
                        disabled
                        value={"Chipsy"}
                        onChange={handleMainBannerChange}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="mainBannerLink">
                <Form.Label column sm="2">Link:</Form.Label>
                <Col sm="10">
                    <Form.Control
                        type="text"
                        name="link"
                        value={mainBanner?.link}
                        onChange={handleMainBannerChange}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="mainBannerSort">
                <Form.Label column sm="2">Sort:</Form.Label>
                <Col sm="10">
                    <Form.Control
                        required
                        type="number"
                        name="sort"
                        value={mainBanner?.sort}
                        onChange={handleMainBannerChange}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="mainBannerVisible">
                <Form.Label column sm="2">Visible:</Form.Label>
                <Col sm="10">
                    <Form.Check
                        type="checkbox"
                        name="visible"
                        checked={mainBanner?.visible}
                        onChange={(e) =>
                            setMainBanner((prev) => ({ ...prev, visible: e.target.checked }))
                        }
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="mainImage">
                <Form.Label column sm="2">Main Image:</Form.Label>
                <Col sm="10">
                    <Form.Control type="file"  required onChange={handleMainImageChange} />
                </Col>
            </Form.Group>

            <h2>Edit Body Banners</h2>
            {bodyBanners !== null && bodyBanners.length > 0 && bodyBanners?.map((banner, index) => (
                <div key={index} className="banner-wrapper mb-4">
                    <h4>Body Banner {index + 1}</h4>
                    <Form.Group as={Row} controlId={`bodyBannerType${index}`}>
                        <Form.Label column sm="2">Type:</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                disabled
                                required
                                type="text"
                                name="type"
                                value={banner.type}
                                onChange={(e) => handleBodyBannerChange(index, e)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId={`bodyBannerBrand${index}`}>
                        <Form.Label column sm="2">Brand:</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                required
                                type="text"
                                name="brand"
                                disabled
                                value={"Chipsy"}
                                onChange={(e) => handleBodyBannerChange(index, e)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId={`bodyBannerLink${index}`}>
                        <Form.Label column sm="2">Link:</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="link"
                                value={banner.link}
                                onChange={(e) => handleBodyBannerChange(index, e)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId={`bodyBannerSort${index}`}>
                        <Form.Label column sm="2">Sort:</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                required
                                type="number"
                                name="sort"
                                value={banner.sort}
                                onChange={(e) => handleBodyBannerChange(index, e)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId={`bodyBannerVisible${index}`}>
                        <Form.Label column sm="2">Visible:</Form.Label>
                        <Col sm="10">
                            <Form.Check
                                type="checkbox"
                                name="visible"
                                checked={banner.visible}
                                onChange={(e) => {
                                    const newBodyBanners = [...bodyBanners];
                                    newBodyBanners[index].visible = e.target.checked;
                                    setBodyBanners(newBodyBanners);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId={`bodyBannerImage${index}`}>
                        <Form.Label column sm="2">Body Banner {index + 1} Image:</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                required
                                type="file"
                                onChange={(e) => handleBodyBannerImageChange(index, e)}
                            />
                        </Col>
                    </Form.Group>

                    <div className="banner-actions mt-3">
                        <Button variant="danger" onClick={() => handleDeleteBodyBanner(index)}>
                            Delete
                        </Button>
                    </div>
                </div>
            ))}

            <Button variant="secondary" className="mt-3 mb-4" onClick={handleAddBodyBanner}>
                Add Body Banner
            </Button>

            <Button variant="primary" type="submit" className="mt-3 mb-4">
                Submit
            </Button>
        </Form>
    );
};

export default ActivityForm;
