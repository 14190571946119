import BannerForm from '../../components/Banner/BannerForm';

const CreateBanner = () => {
  return (
    <div className="container">
      <BannerForm bannerToEdit={null} />
    </div>
  );
};

export default CreateBanner;