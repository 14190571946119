import React from "react";
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NavBar = () => {
  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg" className="px-5">
      <LinkContainer to={"/dashboard/view"}>
        <BootstrapNavbar.Brand>Banner CMS</BootstrapNavbar.Brand>
      </LinkContainer>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavDropdown title="Banner" id="basic-nav-dropdown">
            <LinkContainer to={"/banner/view"}>
              <NavDropdown.Item>Pregled</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={"/banner/create"}>
              <NavDropdown.Item>Kreiraj</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          <NavDropdown title="Activity" id="basic-nav-dropdown">
          <LinkContainer to={"/activity/view"}>
              <NavDropdown.Item>Pregled</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={"/activity/create"}>
              <NavDropdown.Item>Kreiraj</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default NavBar;